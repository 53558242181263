import * as Choices from 'choices.js'

document.addEventListener('turbolinks:load', () => {
    const bulkChecker = document.querySelector(".js-bulk-checker");
    if (bulkChecker) {
        bulkChecker.addEventListener('click', () => {
            const checkBoxes = Array.from(document.querySelectorAll("input[type=checkbox]"));
            checkBoxes.map((c) => {
                c.checked = bulkChecker.checked;
            });
        });
    }

    new Choices(".js-choices", {
        removeItemButton: true,
        // noChoicesText: '選択されていません',
        itemSelectText: "選択して下さい",
    });
});
